<template>
  <TrSection
    v-if="!loading && bill"
    label-id="bills-details"
  >
    <template #head>
      <h2
        id="bills-details"
        class="text-lg font-medium leading-6 text-gray-900"
      >
        {{ bill.period.toHuman() }}
      </h2>
      <p class="mt-1 max-w-2xl text-sm text-gray-500">
        {{ $t('billing.bills.details.head') }}
      </p>
    </template>

    <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-500">
          {{ $t('billing.bills.details.value') }}
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          <Money :value="bill.value" />
        </dd>
      </div>
      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-500">
          {{ $t('billing.bills.details.period') }}
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ bill.period.toHuman() }}
        </dd>
      </div>
      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-500">
          {{ $t('billing.bills.status') }}
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ statusText }}
        </dd>
      </div>
      <div
        v-if="bill.status === BillStatus.ACCOUNTED && bill.billedAt"
        class="sm:col-span-1"
      >
        <dt class="text-sm font-medium text-gray-500">
          {{ $t('billing.bills.details.billedAt') }}
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          <time>{{ bill.billedAt.toHumanFull() }}</time>
        </dd>
      </div>

      <div
        v-if="bill.items.length !== 0"
        class="sm:col-span-2"
        data-cy="bill-details-items"
      >
        <dt class="text-sm font-medium text-gray-500">
          {{ $t('billing.bills.details.items') }}
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          <ul
            role="list"
            class="divide-y divide-gray-200 rounded-md border border-gray-200"
          >
            <li
              v-for="item in bill.items"
              :key="item.id.toString()"
              data-cy="bill-details-item"
              class="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
            >
              <div class="flex w-0 flex-1 items-center">
                <span class="ml-2 w-0 flex-1 truncate">
                  {{ item.title }}
                  <span v-if="isPartialOrderBillItem(item)">
                    -
                    <Link
                      :to="{ name: tenderDetailsRoute.name, params: { id: item.tender.id.toString() } }"
                    > {{ item.tender.title }} </Link>
                  </span>
                </span>
              </div>
              <div class="ml-4 flex-shrink-0">
                <Money :value="item.value" />
              </div>
            </li>
          </ul>
        </dd>
      </div>
    </dl>
  </TrSection>
</template>

<script lang="ts" setup>
import TrSection from '@app/support/TrSection.vue';
import {billingService} from '@app/billing/BillingService';
import {useRoute} from 'vue-router';
import {Uuid} from '@app/uuid/Uuid';
import {computed, ref} from 'vue';
import {Bill} from '@app/billing/Bill';
import Money from '@app/money/money/Money.vue';
import tenderService from '@app/tender/TenderService';
import {BillItemOriginType} from '@app/billing/BillItemOriginType';
import {BillItem, PartialOrderBillItem} from '@app/billing/BillItem';
import Link from '@app/navigation/Link.vue';
import {tenderDetailsRoute} from '@app/tender/routes';
import {evaluateStatusText} from '@app/billing/evaluateStatusText';
import {useI18n} from 'vue-i18n';
import {BillStatus} from '@app/billing/BillStatus';
import {useAppShellBarLoader} from '@app/loader/useAppShellBarLoader';

const route = useRoute();
const billId = Uuid.fromString(route.params.id as string);

const bill = ref<Bill | undefined>();

const loading = ref(false);

const {t} = useI18n();

const statusText = computed(() => bill.value ? evaluateStatusText(t, bill.value?.status) : undefined);

const isPartialOrderBillItem = (billItem: BillItem): billItem is PartialOrderBillItem => {
  return billItem.originType === BillItemOriginType.PARTIAL_ORDER;
};

const fetchDetails = async () => {
  loading.value = true;

  try {
    const {data: billData} = await billingService.fetchDetails(billId);
    bill.value = billData;

    const partialOrderIds = bill.value.items
        .filter(isPartialOrderBillItem)
        .map((billItem) => billItem.originId);

    const {data: lookupData} = await tenderService.lookupTendersByPartialOrderIds(partialOrderIds);

    bill.value.items = billData.items
        .filter(isPartialOrderBillItem)
        .map((billItem: BillItem): PartialOrderBillItem => {
          const tender = lookupData.get(billItem.originId.toString());

          if (!tender) {
            throw new Error('Partial Order id not found in map');
          }

          return {
            ...billItem,
            tender,
          };
        });
  } finally {
    loading.value = false;
  }
};

useAppShellBarLoader(loading);

fetchDetails();
</script>
