<template>
  <div>
    <Combobox
      v-slot="{ open }"
      :model-value="modelValue"
      as="div"
      v-bind="$attrs"
      :disabled="disabled"
      @update:model-value="onModelValueChanged($event)"
    >
      <ComboboxLabel
        v-if="label"
        class="block text-sm font-medium text-gray-700 text-left"
      >
        {{ label }}
      </ComboboxLabel>
      <div
        :class="{
          'relative': true,
          'mt-1': label
        }"
      >
        <ComboboxInput
          ref="input"
          :title="title"
          :class="{
            'appearance-none rounded-md block w-full focus:outline-none bg-white sm:text-sm': true,
            'border-gray-300': !disabled,
            'focus:ring-primary-500 focus:border-primary-500 placeholder-gray-400': !hasErrors,
            'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500': hasErrors,
            'border-gray-200 text-gray-700 placeholder-gray-300': disabled
          }"
          autocomplete="off"
          :placeholder="placeholder"
          :display-value="resolveLabel"
          :required="required"
          @change="$emit('change', $event)"
        />
        <ComboboxButton
          class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
          data-cy="combobox-button"
          :title="title"
        >
          <ChevronUpDownIcon
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </ComboboxButton>

        <ComboboxOptions
          v-if="(open && itemsSize !== 0) || showNoMatchesMessage"
          static
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <div
            v-if="showNoMatchesMessage"
            class="cursor-default select-none relative py-2 px-4 text-gray-700"
          >
            {{ noMatches }}
          </div>

          <ComboboxOption
            v-for="(item, key) in items"
            :key="key"
            v-slot="{ active, selected }"
            :value="item"
            as="template"
          >
            <li
              :data-key="key"
              :class="{
                'relative cursor-default select-none py-2 pl-3 pr-9': true,
                'bg-primary-600 text-white': active,
                'text-gray-900': !active,
              }"
            >
              <span :class="['block truncate', selected && 'font-semibold']">
                {{ resolveLabel(item) }}
              </span>

              <span
                v-if="selected"
                :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-primary-600']"
              >
                <CheckIcon
                  class="h-5 w-5"
                  aria-hidden="true"
                />
              </span>
            </li>
          </ComboboxOption>
          <slot name="notice" />
        </ComboboxOptions>
      </div>
    </Combobox>

    <FieldErrors :errors="combinedErrors" />
  </div>
</template>

<script lang="ts" setup>
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/vue/20/solid';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/vue';
import {computed, ref, toRefs} from 'vue';
import Rule from '@app/forms/rules/Rule';
import {useErrorHandling} from '@app/forms/useErrorHandling';
import {comboboxItem} from '@app/forms/comboboxItem';
import FieldErrors from '@app/forms/FieldErrors.vue';

defineOptions({
    inheritAttrs: false,
});

interface Props {
  modelValue: comboboxItem;
  label?: string;
  items: Record<string | number, comboboxItem>;
  resolveLabel: (item: unknown) => string;
  query: string;
  errors?: string[];
  noMatches?: string;
  required?: boolean;
  placeholder?: string;
  rules?: Rule<comboboxItem>[];
  disabled?: boolean,
  disabledHint?: string,
}

const props = withDefaults(defineProps<Props>(), {
  required: false,
  placeholder: undefined,
  label: undefined,
  noMatches: undefined,
  errors: () => [],
  rules: () => [],
  disabled: false,
  disabledHint: undefined,
});

const emit = defineEmits<{
  (e: 'update:model-value', value: unknown): void,
  (e: 'change', payload: Event): void
}>();

const {
  modelValue,
  items,
  errors,
  noMatches,
  query,
  rules,
  label,
  disabled,
  disabledHint,
} = toRefs(props);

const input = ref<typeof ComboboxInput|null>(null);

const focus = () => {
  input.value?.$el.focus();
};

defineExpose({
  focus,
});

const {resetInternalErrors, hasErrors, combinedErrors} = useErrorHandling(rules, modelValue, `combobox (${label.value})`, errors);

const showNoMatchesMessage = computed(() => itemsSize.value === 0 && query.value !== '' && !!noMatches.value);

const itemsSize = computed(() => Object.values(items.value).length);

const onModelValueChanged = (event: unknown) => {
  emit('update:model-value', event);

  resetInternalErrors();
};

const title = computed(() => disabled.value && disabledHint.value ? disabledHint.value : undefined);
</script>
