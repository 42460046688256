import {userId} from '@app/auth/Auth';
import {
    fetchActiveTenderFilterFromBackend,
    fetchActiveTruckOfferFilterFromBackend,
    updateActiveTenderFilterInBackend, updateActiveTruckOfferFilterInBackend,
} from '@app/tender/filter/BackendFilterStorage';
import {NotifiableTenderSearchParams} from '@app/tender/search/TenderSearchParams';
import {NotifiableTruckOfferSearchParams} from '@app/truckoffer/search/TruckOfferSearchParams';

export async function fetchActiveTenderFilter(): Promise<NotifiableTenderSearchParams> {
    if (!userId.value) {
        throw 'Cannot fetch filter of unauthenticated user!';
    }

    return fetchActiveTenderFilterFromBackend(userId.value);
}

export async function updateActiveTenderFilter(searchParams: NotifiableTenderSearchParams): Promise<void> {
    if (!userId.value) {
        throw 'Cannot update filter of unauthenticated user!';
    }

    return updateActiveTenderFilterInBackend(userId.value, searchParams);
}

export async function fetchActiveTruckOfferFilter(): Promise<NotifiableTruckOfferSearchParams> {
    if (!userId.value) {
        throw 'Cannot fetch filter of unauthenticated user!';
    }

    return fetchActiveTruckOfferFilterFromBackend(userId.value);
}

export async function updateActiveTruckOfferFilter(searchParams: NotifiableTruckOfferSearchParams): Promise<void> {
    if (!userId.value) {
        throw 'Cannot update filter of unauthenticated user!';
    }

    return updateActiveTruckOfferFilterInBackend(userId.value, searchParams);
}
