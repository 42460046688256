<template>
  <TrCombobox
    ref="combobox"
    :model-value="modelValue"
    :items="options"
    :resolve-label="labelFromTimeString"
    :label="label"
    :query="query"
    :rules="rules"
    :errors="errors"
    @update:model-value="$emit('update:model-value', $event as TimeString|undefined)"
    @change="onInputChange($event)"
  />
</template>

<script lang="ts" setup>
import TrCombobox from '@app/forms/TrCombobox.vue';
import {computed, ref, toRefs} from 'vue';
import TimeOptionsGenerator from '@app/time/TimeOptionsGenerator';
import {TimeString} from '@app/support/TimeString';
import Time from '@app/time/Time';
import keyBy from 'lodash/keyBy';
import Rule from '@app/forms/rules/Rule';
import {comboboxItem} from '@app/forms/comboboxItem';
import TrComboBox from '@app/forms/TrCombobox.vue';

interface Props {
  modelValue?: TimeString;
  min?: TimeString;
  max?: TimeString;
  label: string;
  errors?: string[];
  rules?: Rule<comboboxItem>[];
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  min: '00:00',
  max: '23:59',
  errors: () => [],
  rules: () => [],
});

defineEmits<{
  (e: 'update:model-value', v: Props['modelValue']): void,
}>();

const combobox = ref<typeof TrComboBox|null>(null);

const focus = () => {
  combobox.value?.focus();
};

defineExpose({
  focus,
});

const {
  min,
  max,
} = toRefs(props);

const query = ref('');

function makeTimeOptions() {
  let fromTime: Time | undefined;

  try {
    fromTime = Time.parseIsoUTC(min.value);
  }catch (e) {
    fromTime = Time.parseIsoUTC('00:00');
  }

  let maxTime: Time | undefined;

  try {
    maxTime = Time.parseIsoUTC(max.value);
  }catch (e) {
    maxTime = Time.parseIsoUTC('23:59');
  }

  return TimeOptionsGenerator.make()
      .withFrom(fromTime)
      .withTo(maxTime)
      .withInterval(5)
      .generateStrings();
}

const timeOptions = Array.from(makeTimeOptions());
const options = computed((): Record<string, string> => {
  const maxFilterLength = Math.max(4, query.value.length);
  const firstCharsOfFilter = query.value.substring(0, maxFilterLength)
      .replace(':', '');


  if (query.value.length === 0) {
    const fullHoursFilter = (option: string) => option.endsWith('00');
    const filteredOptions = timeOptions.filter(fullHoursFilter);
    return keyBy(filteredOptions);
  }

  const optionMatchesFilter = (option: string) => option.replace(':', '')
      .includes(firstCharsOfFilter);

  const filteredOptions = timeOptions.filter(optionMatchesFilter)
      .slice(0, 10);
  return keyBy(filteredOptions);
});

function onInputChange(event: Event) {
  const eventTarget = event.target as HTMLInputElement;
  query.value = eventTarget.value;
}

const labelFromTimeString = (timeString: unknown) => {
  if (typeof timeString !== 'string') {
    return '';
  }
  return timeString;
};
</script>
