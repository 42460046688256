<template>
  <div class="flex flex-wrap">
    <div class="w-full xl:pr-2 xl:w-1/3">
      <TruckTypeSelect
        :model-value="truckType"
        :label="$t('tender.slot.truckType')"
        :rules="[required()]"
        @update:model-value="$emit('update:truckType', $event as typeof truckType)"
      />
    </div>

    <div class="w-1/2 mt-4 xl:mt-0 pr-2 xl:w-1/3">
      <TruckBedTypeSelect
        :model-value="truckBedType"
        :label="$t('tender.slot.truckBedType')"
        :rules="[required()]"
        @update:model-value="$emit('update:truckBedType', $event as typeof truckBedType)"
      />
    </div>

    <div class="w-1/2 mt-4 xl:mt-0 xl:pr-2 xl:w-1/3">
      <TruckFeatureSelect
        :model-value="truckFeatures"
        multiple
        :label="$t('tender.slot.truckFeature')"
        @update:model-value="$emit('update:truckFeatures', $event)"
      />
    </div>
  </div>

  <MultiFrame>
    <template #top>
      <span class="bg-white px-3 text-base leading-6 text-gray-900">Kontaktpersonen</span>
    </template>
    <div
      v-for="(participant, id) in participants"
      :key="id"
      class="flex flex-col md:flex-row md:space-x-3 space-y-6 md:space-y-0"
    >
      <div class="w-full md:w-1/2">
        <TrCompanyUsersComboBox
          :ref="(el) => addParticipantCombobox(el, id)"
          :model-value="participant.user"
          data-cy="participant-combo-box"
          :blacklist="usedParticipantsIds"
          :label="$t('truckOffer.create.form.contact.user')"
          :rules="[required()]"
          should-default-current-user
          @update:model-value="updateParticipant(id, 'user', $event)"
        />
      </div>

      <div class="w-full md:w-1/2 flex flex-col md:flex-row">
        <div class="flex-1">
          <TruckOfferRoleSelect
            :model-value="participant.role"
            :label="$t('truckOffer.create.form.contact.role')"
            @update:model-value="updateParticipant(id, 'role', $event)"
          />
        </div>

        <div class="flex-none md:ml-3">
          <TrLabel
            :for="`participant-remove-${id}`"
            class="invisible"
          >
            löschen
          </TrLabel>
          <div class="mt-1">
            <TrButton
              :id="`participant-remove-${id}`"
              :disabled="!allowParticipantDeletion"
              type="button"
              :color-scheme="ButtonColorScheme.TERTIARY"
              @click="removeParticipant(id)"
            >
              <TrashIcon class="h-5" />
            </TrButton>
          </div>
        </div>
      </div>
    </div>

    <template #bottom>
      <div class="px-3 bg-white">
        <TrButton
          type="button"
          :color-scheme="ButtonColorScheme.SECONDARY"
          @click="addParticipantAndFocus"
        >
          {{ $t('truckOffer.create.form.contact.add') }}
          <template #postfix>
            <PlusIcon class="w-5 h-5" />
          </template>
        </TrButton>
      </div>
    </template>
  </MultiFrame>

  <div>
    <TrLabel for="date-of-execution">
      {{ $t('truckOffer.create.form.dateOfExecution') }}
    </TrLabel>

    <DateInput
      id="date-of-execution"
      :model-value="dateOfExecution"
      name="date-of-availablility"
      :rules="[required()]"
      :min-date="Calendar.fromDateTime(clock.now())"
      @update:model-value="$emit('update:dateOfExecution', $event)"
    />
  </div>

  <div class="flex flex-col md:flex-row md:space-x-3 space-y-6 md:space-y-0">
    <div class="flex-1">
      <TimeCombobox
        :label="$t('truckOffer.create.form.timeFrom')"
        :model-value="timeFrom"
        :rules="[required()]"
        @update:model-value="$emit('update:timeFrom', $event)"
      />
    </div>
    <div class="flex-1">
      <TimeCombobox
        :label="$t('truckOffer.create.form.timeTo')"
        :model-value="timeTo"
        :rules="[required()]"
        @update:model-value="$emit('update:timeTo', $event)"
      />
    </div>
  </div>
  <div class="flex flex-col md:flex-row md:space-x-3 space-y-6 md:space-y-0">
    <div class="flex-1">
      <TrLocationCombobox
        :model-value="locationOption"
        :location-details="location"
        :label="$t('truckOffer.create.form.location')"
        :rules="[required()]"
        @update:model-value="$emit('update:locationOption', $event)"
        @update:location-details="$emit('update:location', $event)"
      />
    </div>

    <div class="flex-1">
      <TrLabeledInput
        :model-value="perimeter"
        :label="$t('truckOffer.create.form.perimeter')"
        :rules="[required()]"
        @update:model-value="$emit('update:perimeter', $event)"
      />
    </div>
  </div>

  <div class="flex items-center space-x-3 lg:w-1/2">
    <Checkbox
      :checked="paymentPerTon"
      @change="$emit('update:paymentPerTon', !paymentPerTon)"
    >
      {{ $t('tender.paymentScheme.per_ton') }}
    </Checkbox>
    <div class="flex-1">
      <TrInput
        name="payment-per-ton-amount"
        :model-value="paymentPerTonAmount"
        type="number"
        :rules="[positiveDecimal(), requiredIf(ref(paymentPerTon))]"
        @update:model-value="updatePaymentPerTonAmount($event)"
      />
    </div>
  </div>

  <div class="flex items-center space-x-3 lg:w-1/2">
    <Checkbox
      :checked="paymentPerHour"
      @change="$emit('update:paymentPerHour', !paymentPerHour)"
    >
      {{ $t('tender.paymentScheme.per_hour') }}
    </Checkbox>
    <div class="flex-1">
      <TrInput
        name="payment-per-hour-amount"
        :model-value="paymentPerHourAmount"
        type="number"
        :rules="[positiveDecimal(), requiredIf(ref(paymentPerHour))]"
        @update:model-value="updatePaymentPerHourAmount($event)"
      />
    </div>
  </div>

  <FieldErrors :errors="paymentInvalid ? [$t('truckOffer.create.form.paymentInvalid')] : []" />

  <TrLabeledInput
    :model-value="title"
    name="title"
    :label="$t('truckOffer.create.form.title')"
    :rules="[required()]"
    @update:model-value="$emit('update:title', $event)"
  />

  <TrTextarea
    :model-value="description"
    name="description"
    :rules="[required()]"
    :label="$t('truckOffer.create.form.description')"
    @update:model-value="$emit('update:description', $event)"
  />
</template>
<script setup lang="ts">
import TrButton from '@app/support/TrButton.vue';
import TrLabel from '@app/forms/TrLabel.vue';
import TruckBedTypeSelect from '@app/tender/create/TruckBedTypeSelect.vue';
import TruckTypeSelect from '@app/tender/create/TruckTypeSelect.vue';
import TrTextarea from '@app/forms/TrTextarea.vue';
import DateInput from '@app/forms/DateInput.vue';
import PlusIcon from '@heroicons/vue/24/solid/PlusIcon';
import TrashIcon from '@heroicons/vue/24/outline/TrashIcon';
import TrLabeledInput from '@app/forms/LabeledInput.vue';
import {required} from '@app/forms/rules/required';
import TrCompanyUsersComboBox from '@app/company/CompanyUsersCombobox.vue';
import TrLocationCombobox from '@app/forms/LocationCombobox.vue';
import Calendar from '@app/time/Calendar';
import {LocationDetails, LocationOption} from '@app/forms/Location';
import {ComponentPublicInstance, computed, nextTick, reactive, ref, toRefs} from 'vue';
import {UuidString} from '@app/uuid/UuidString';
import {ButtonColorScheme} from '@app/support/ButtonColorScheme';
import UuidFactory from '@app/uuid/UuidFactory';
import {TruckType} from '@app/tender/trucks/TruckType';
import {TruckBedType} from '@app/tender/trucks/TruckBedType';
import clock from '@app/time/Clock';
import {pickBy} from 'lodash';
import MultiFrame from '@app/forms/MultiFrame.vue';
import TruckFeatureSelect from '@app/tender/create/TruckFeatureSelect.vue';
import {positiveDecimal} from '@app/forms/rules/positiveDecimal';
import {TruckFeature} from '@app/tender/trucks/TruckFeature';
import Checkbox from '@app/forms/checkbox/Checkbox.vue';
import TrInput from '@app/forms/TrInput.vue';
import TruckOfferRoleSelect from '@app/truckoffer/TruckOfferRoleSelect.vue';
import {TruckOfferParticipant} from '@app/truckoffer/formTypes';
import {truckOfferRoles} from '@app/truckoffer/TruckOfferRole';
import TimeCombobox from '@app/support/TimeCombobox.vue';
import {TimeString} from '@app/support/TimeString';
import {requiredIf} from '@app/forms/rules/requiredIf';
import FieldErrors from '@app/forms/FieldErrors.vue';

interface Props {
  participants?: Record<UuidString, TruckOfferParticipant>,
  title?: string,
  description?: string,
  dateOfExecution?: Calendar,
  location?: LocationDetails,
  locationOption?: LocationOption
  truckType: TruckType,
  truckBedType: TruckBedType,
  truckFeatures: TruckFeature[],
  perimeter?: string,
  paymentPerTon: boolean,
  paymentPerTonAmount?: string,
  paymentPerHour: boolean,
  paymentPerHourAmount?: string,
  timeFrom?: TimeString,
  timeTo?: TimeString,
  paymentInvalid: boolean
}

interface Events {
  (e: 'update:participants', v: Props['participants']): void

  (e: 'update:title', v: Props['title']): void

  (e: 'update:description', v: Props['description']): void

  (e: 'update:dateOfExecution', v: Props['dateOfExecution']): void

  (e: 'update:paymentPerTon', v: Props['paymentPerTon']): void

  (e: 'update:paymentPerTonAmount', v: Props['paymentPerTonAmount']): void

  (e: 'update:paymentPerHour', v: Props['paymentPerHour']): void

  (e: 'update:paymentPerHourAmount', v: Props['paymentPerHourAmount']): void

  (e: 'update:location', v: Props['location']): void

  (e: 'update:locationOption', v: Props['locationOption']): void

  (e: 'update:truckType', v: Props['truckType']): void

  (e: 'update:truckBedType', v: Props['truckBedType']): void

  (e: 'update:truckFeatures', v: Props['truckFeatures']): void

  (e: 'update:perimeter', v: Props['perimeter']): void

  (e: 'update:timeFrom', v: Props['timeFrom']): void

  (e: 'update:timeTo', v: Props['timeTo']): void
}

const props = withDefaults(defineProps<Props>(), {
  allowEditingTakenSlots: false,
  participants: undefined,
  title: undefined,
  description: undefined,
  dateOfExecution: undefined,
  paymentAmountSuggestion: undefined,
  location: undefined,
  locationOption: undefined,
  slots: undefined,
  perimeter: undefined,
  paymentPerTonAmount: undefined,
  paymentPerHourAmount: undefined,
  timeFrom: undefined,
  timeTo: undefined,
});

const {
  participants: participantsProp,
  paymentPerTon,
  paymentPerHour,
} = toRefs(props);

const emit = defineEmits<Events>();

const participantComboboxes = reactive(new Map<UuidString, typeof TrCompanyUsersComboBox>);

const usedParticipantsIds = computed(() => {
  const participantIds = Object.values(participantsProp?.value ?? {})
      .filter(({user}) => !!user)
      .map(({user}) => user!.id.toString());

  return new Set(participantIds);
});

function addParticipantAndFocus() {
  const id = UuidFactory.v4();

  emit('update:participants', {
    ...participantsProp?.value,
    [id.toString()]: {
      role: truckOfferRoles.DRIVER,
    },
  });

  // focus the new combobox
  nextTick(() => {
    participantComboboxes.get(id.toString())?.focus();
  });
}

function removeParticipant(participantId: UuidString) {
  emit('update:participants', pickBy(participantsProp?.value, (_, key) => key !== participantId));

  participantComboboxes.delete(participantId);
}

/** keep map of comboboxes for focusing */
function addParticipantCombobox(el: Element | ComponentPublicInstance | null, id: UuidString) {
  const combobbox = el as unknown as typeof TrCompanyUsersComboBox;
  participantComboboxes.set(id, combobbox);
}

function updateParticipant<T extends keyof TruckOfferParticipant>(participantId: UuidString, prop: T, value: TruckOfferParticipant[T]) {
  const updatedParticipant = {
    ...participantsProp!.value![participantId],
    [prop]: value,
  };


  emit('update:participants', {
    ...participantsProp?.value,
    [participantId]: updatedParticipant,
  });
}

const allowParticipantDeletion = computed(() => participantsProp?.value && Object.keys(participantsProp.value).length > 1);

function updatePaymentPerTonAmount(value?: string) {
  if (!paymentPerTon.value) {
    emit('update:paymentPerTon', true);
  }

  emit('update:paymentPerTonAmount', value);
}

function updatePaymentPerHourAmount(value?: string) {
  if (!paymentPerHour.value) {
    emit('update:paymentPerHour', true);
  }

  emit('update:paymentPerHourAmount', value);
}
</script>
