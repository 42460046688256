import {ResponseContract} from '@app/http/ResponseContract';
import {SimpleTender} from '@app/tender/SimpleTender';
import httpClient from '@app/http/HttpClient';
import type {DateString} from '@app/support/DateString';
import {TimeString} from '@app/support/TimeString';
import {UuidString} from '@app/uuid/UuidString';
import {PaymentScheme} from '@app/tender/PaymentScheme';
import {Tender} from '@app/tender/Tender';
import Time from '@app/time/Time';
import Calendar from '@app/time/Calendar';
import {TenderFiltersContract} from '@app/tender/search/TenderFiltersContract';
import Kilometers from '@app/location/distance/Kilometers';
import {Uuid} from '@app/uuid/Uuid';
import {mapMinimalCompany, MinimalCompanyResponse} from '@app/company/MinimalCompany';
import {Money} from '@app/money/Money';
import {Currency} from '@app/money/Currency';
import {PartialOrder, PartialOrderStatus} from '@app/tender/PartialOrder';
import {TenderStatus} from '@app/tender/TenderStatus';
import {DateTime} from '@app/time/DateTime';
import {User} from '@app/auth/User';
import {TenderLookupRequestPayload} from '@app/tender/lookup/TenderLookupRequestPayload';
import {TenderLookupResponsePayload} from '@app/tender/lookup/TenderLookupResponsePayload';
import {MinimalTender} from '@app/tender/MinimalTender';
import {SlotAcceptRequestPayload} from '@app/tender/accept/SlotAcceptRequestPayload';
import {LocationDetails} from '@app/forms/Location';
import {TenderContactPerson} from '@app/tender/TenderContactPerson';
import {TenderRole} from '@app/tender/TenderRole';
import {TruckType} from '@app/tender/trucks/TruckType';
import {TruckBedType} from '@app/tender/trucks/TruckBedType';
import {TenderSlot} from '@app/tender/TenderSlot';
import {TruckFeature} from '@app/tender/trucks/TruckFeature';
import {VerifyCostActionInfo} from '@app/tender/VerifyCostActionInfo';

export interface TendersRequestPayload {
    companyId?: UuidString,
    /** greater than */
    dateOfExecutionGt?: DateString,
    /** later than */
    dateOfExecutionLt?: DateString,
    page?: number,
    pageSize?: number,
    locationRadius?: number,
    location?: {
        latitude?: number,
        longitude?: number,
    },
    hasPartialOrdersFromContractor?: UuidString,
    truckTypes?: TruckType[]
}

export interface TendersResponseItem {
    id: UuidString,
    title: string,
    description: string,
    trucksLeft: number,
    trucksRequired: number,
    locationDisplayName: string,
    unloadLocationDisplayName: string,
    dateOfExecution: DateString,
    timeFrom: TimeString,
    timeTo: TimeString,
    distance: number,
    unloadDistance: number,
    status: TenderStatus
}

export interface TendersResponsePayloadRaw {
    items: TendersResponseItem[]
    totalItems: number
}

export interface TendersResponsePayload {
    items: SimpleTender[]
    totalItems: number
}

export interface TenderListResponsePayload {
    name: string
    items: TendersResponseItem[]
}

export interface TenderList {
    name: string
    items: SimpleTender[]
}

export interface TenderParticipant {
    userId: UuidString;
    role: TenderRole;
}

export type CreateTenderRequestPayload = {
    companyId: UuidString,
    participants: TenderParticipant[]
    title: string,
    description: string,
    dateOfExecution: DateString,
    slots: SlotPayload[],
    paymentScheme: PaymentScheme,
    paymentAmountSuggestion: number
    location: LocationDetails,
    unloadLocation: LocationDetails,
};

export interface SlotPayload {
    id?: UuidString
    timeFrom: TimeString,
    timeTo: TimeString,
    truckTypes: TruckType[],
    truckBedTypes: TruckBedType[],
    truckFeatures: TruckFeature[]
}

interface TenderDetailsResponsePartialOrder {
    id: UuidString,
    status: PartialOrderStatus,
    message: string,
    paymentAmount: number,
    contractor: MinimalCompanyResponse,
    createdAt: DateString,
    createdBy: TenderDetailsResponseUser
}

interface TenderDetailsResponseUser {
    id: UuidString,
    firstName: string,
    lastName: string
}

export type TenderDetailsResponse = {
    id: UuidString,
    company: MinimalCompanyResponse,
    trucksRequired: number,
    title: string,
    description: string,
    trucksLeft: number,
    dateOfExecution: DateString,
    timeFrom: TimeString,
    timeTo: TimeString,
    participants: {
        user: {
            id: UuidString,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string
        },
        tenderRole: TenderRole
    }[]
    paymentScheme: PaymentScheme,
    paymentAmountSuggestion?: number,
    slots: TenderDetailsResponseSlot[],
    status: TenderStatus,
    location: LocationDetails,
    unloadLocation: LocationDetails,
    createdByCurrentUser: boolean,
    createdAt: TimeString,
    openedCount: number
};

interface TenderDetailsResponseSlot {
    id: UuidString,
    taken: boolean,
    timeFrom: TimeString,
    timeTo: TimeString,
    truckTypes: [TruckType],
    truckBedTypes: [TruckBedType],
    truckFeatures: [TruckFeature],
    partialOrders: TenderDetailsResponsePartialOrder[],
}

export interface VerifyCostActionResponsePayload {
    hasAccountingInformation: boolean
    estimateCost: number
}

class TenderService {
    async search(filterParameter: TenderFiltersContract, page = 1, pageSize = 10): Promise<ResponseContract<TendersResponsePayload>> {
        const payload = TenderService.mapFiltersToSearchPayload(filterParameter);
        payload.page = page;
        payload.pageSize = pageSize;

        const res = await httpClient.post<TendersResponsePayloadRaw>('/public/tenders/search', payload);

        const tenders = res.data.items.map(TenderService.mapSearchResponseItemToSimpleTender);

        return TenderService.hydrateSearchResponseWithSimpleTenders(res, tenders);
    }

    async fetchTenderList(id: Uuid): Promise<ResponseContract<TenderList>> {
        const res = await httpClient.get<TenderListResponsePayload>(`/secure/tenders/lists/${id.toString()}`);

        const tenders = res.data.items.map(TenderService.mapSearchResponseItemToSimpleTender);

        return TenderService.hydrateTenderListResponseWithSimpleTenders(res, tenders);
    }

    async create(payload: CreateTenderRequestPayload): Promise<ResponseContract<Uuid>> {
        const res = await httpClient.post<UuidString>('/secure/tenders', payload);
        return {
            ...res,
            data: Uuid.fromString(res.data),
        };
    }

    async update(id: Uuid, payload: CreateTenderRequestPayload): Promise<ResponseContract<void>> {
        return await httpClient.put(`/secure/tenders/${id.toString()}`, payload);
    }

    async fetchTenderDetails(tenderId: Uuid): Promise<ResponseContract<Tender>> {
        const res = await httpClient.get<TenderDetailsResponse>(`/public/tenders/${tenderId.toString()}`);

        return TenderService.mapDetailsResponse(res);
    }

    async rejectPartialOrder(tenderId: Uuid, partialOrderId: Uuid): Promise<void> {
        await httpClient.post(`/secure/tenders/${tenderId}/orders/${partialOrderId}/status/${PartialOrderStatus.REJECTED}`);
    }

    async verifyAcceptPartialOrder(tenderId: Uuid, partialOrderId: Uuid): Promise<VerifyCostActionInfo> {
        const {data} = await httpClient.post<VerifyCostActionResponsePayload>(`/secure/tenders/${tenderId}/orders/${partialOrderId}/status/${PartialOrderStatus.ACCEPTED}/verify`);
        return this.mapCostInfo(data);
    }

    async acceptPartialOrder(tenderId: Uuid, partialOrderId: Uuid): Promise<void> {
        return await httpClient.post(`/secure/tenders/${tenderId}/orders/${partialOrderId}/status/${PartialOrderStatus.ACCEPTED}`);
    }

    async revokePartialOrder(tenderId: Uuid, partialOrderId: Uuid): Promise<void> {
        await httpClient.post(`/secure/tenders/${tenderId}/orders/${partialOrderId}/status/${PartialOrderStatus.REVOKED}`);
    }

    async confirmPartialOrder(tenderId: Uuid, partialOrderId: Uuid): Promise<void> {
        await httpClient.post(`/secure/tenders/${tenderId}/orders/${partialOrderId}/status/${PartialOrderStatus.PENDING}`);
    }

    async lookupTendersByPartialOrderIds(partialOrderIds: Uuid[]): Promise<ResponseContract<Map<UuidString, MinimalTender>>> {
        const requestPayload: TenderLookupRequestPayload = {
            partialOrderIds: partialOrderIds.map((partialOrderId) => partialOrderId.toString()),
        };
        const tenderLookupResponse: ResponseContract<TenderLookupResponsePayload> = await httpClient.post('/secure/tenders/lookup/', requestPayload);


        const partialOrderToTenderMap = new Map<UuidString, MinimalTender>();

        const idToTenders = new Map<UuidString, MinimalTender>();
        tenderLookupResponse.data.tenders.forEach(tender => {
            const minimalTender = {
                id: Uuid.fromString(tender.id),
                title: tender.title,
            };
            idToTenders.set(tender.id, minimalTender);
        });

        partialOrderIds.forEach((partialOrderId) => {
            const tenderIdForPartialOrder = tenderLookupResponse.data.mappings[partialOrderId.toString()] ?? undefined;
            if (!tenderIdForPartialOrder) {
                return;
            }

            const minimalTender = idToTenders.get(tenderIdForPartialOrder);
            if (!minimalTender) {
                return;
            }

            partialOrderToTenderMap.set(partialOrderId.toString(), minimalTender);
        });


        return {
            ...tenderLookupResponse,
            data: partialOrderToTenderMap,
        };
    }

    async verifyAcceptSlot(tenderId: Uuid, slotId: Uuid, contractorId: Uuid): Promise<VerifyCostActionInfo> {
        const verifyAcceptPayload: SlotAcceptRequestPayload = {
            contractorId: contractorId.toString(),
            // FAKE DATA, NOT USED BY BACKEND
            message: '',
            paymentAmount: 1,
        };

        const {data} = await httpClient.post<VerifyCostActionResponsePayload>(`/secure/tenders/${tenderId.toString()}/slots/${slotId.toString()}/orders/verify`, verifyAcceptPayload);
        return this.mapCostInfo(data);
    }

    async accept(tenderId: Uuid, slotId: Uuid, contractorId: Uuid, message: string, paymentAmount: number): Promise<ResponseContract<void>> {
        const acceptPayload: SlotAcceptRequestPayload = {
            contractorId: contractorId.toString(),
            message,
            paymentAmount,
        };

        return httpClient.post(`/secure/tenders/${tenderId.toString()}/slots/${slotId.toString()}/orders`, acceptPayload);
    }

    async deactivateFilterNotification(userId: Uuid, filterId: Uuid) {
        return httpClient.post<''>(`/secure/users/${userId.toString()}/filter/${filterId.toString()}/disable-notification`);
    }

    private mapCostInfo(data: VerifyCostActionResponsePayload): VerifyCostActionInfo {
        return {
            estimatedCosts: Money.fromNumber(data.estimateCost, Currency.EUR),
            hasAccountingInfo: data.hasAccountingInformation,
        };
    }

    private static mapDetailsResponse(res: ResponseContract<TenderDetailsResponse>): ResponseContract<Tender> {
        return {
            ...res,
            data: this.mapTenderDetailsResponsePayload(res.data),
        };
    }

    private static mapTenderDetailsResponsePayload(data: TenderDetailsResponse): Tender {
        return {
            ...data,
            id: Uuid.fromString(data.id),
            dateOfExecution: Calendar.parseIsoUTC(data.dateOfExecution),
            timeFrom: Time.parseIsoUTC(data.timeFrom),
            timeTo: Time.parseIsoUTC(data.timeTo),
            participants: this.mapParticipants(data),
            slots: TenderService.mapSlots(data.slots),
            paymentAmountSuggestion: data.paymentAmountSuggestion ? Money.fromNumber(data.paymentAmountSuggestion, Currency.EUR) : undefined,
            createdAt: DateTime.parseIsoUTC(data.createdAt),
            company: mapMinimalCompany(data.company),
        };
    }

    private static mapParticipants = (data: TenderDetailsResponse): TenderContactPerson[] =>
        data.participants.map(participant => ({
                id: Uuid.fromString(participant.user.id),
                firstName: participant.user.firstName,
                lastName: participant.user.lastName,
                role: participant.tenderRole,
                email: participant.user.email,
                phoneNumber: participant.user.phoneNumber,
            }),
        );

    private static mapPartialOrders(partialOrders: TenderDetailsResponsePartialOrder[]): PartialOrder[] {
        return partialOrders.map(TenderService.mapPartialOrder);
    }

    private static mapPartialOrder(partialOrder: TenderDetailsResponsePartialOrder): PartialOrder {
        return {
            ...partialOrder,
            id: Uuid.fromString(partialOrder.id),
            createdAt: DateTime.parseIsoUTC(partialOrder.createdAt),
            createdBy: TenderService.mapUser(partialOrder.createdBy),
            paymentAmount: Money.fromNumber(partialOrder.paymentAmount, Currency.EUR),
            contractor: mapMinimalCompany(partialOrder.contractor),
        };
    }

    private static mapUser(user: TenderDetailsResponseUser): User {
        return {
            ...user,
            id: Uuid.fromString(user.id),
        };
    }

    private static hydrateSearchResponseWithSimpleTenders(searchResponse: ResponseContract<TendersResponsePayloadRaw>, tenders: SimpleTender[]) {
        return {
            ...searchResponse,
            data: {
                ...searchResponse.data,
                items: tenders,
            },
        };
    }

    private static hydrateTenderListResponseWithSimpleTenders(tenderListResponse: ResponseContract<TenderListResponsePayload>, tenders: SimpleTender[]) {
        return {
            ...tenderListResponse,
            data: {
                ...tenderListResponse.data,
                items: tenders,
            },
        };
    }

    private static mapSearchResponseItemToSimpleTender(tenderItem: TendersResponseItem): SimpleTender {
        return {
            ...tenderItem,
            dateOfExecution: Calendar.parseIsoUTC(tenderItem.dateOfExecution),
            timeFrom: Time.parseIsoUTC(tenderItem.timeFrom),
            timeTo: Time.parseIsoUTC(tenderItem.timeTo),
            distance: Kilometers.of(tenderItem.distance),
            unloadDistance: Kilometers.of(tenderItem.unloadDistance),
            id: Uuid.fromString(tenderItem.id),
            status: tenderItem.status,
        };
    }

    private static mapFiltersToSearchPayload(filterParameter: TenderFiltersContract): TendersRequestPayload {
        return {
            locationRadius: filterParameter.locationRadius ? Number(filterParameter.locationRadius) : undefined,
            location: filterParameter.latitude && filterParameter.longitude ? {
                latitude: filterParameter.latitude,
                longitude: filterParameter.longitude,
            } : undefined,
            dateOfExecutionGt: filterParameter.fromDateTime?.toISO(),
            dateOfExecutionLt: filterParameter.toDateTime?.toISO(),
            companyId: filterParameter.companyId,
            hasPartialOrdersFromContractor: filterParameter.hasPartialOrdersFromContractor,
            truckTypes: filterParameter.truckTypes,
        };
    }

    private static mapSlots(slots: TenderDetailsResponseSlot[]): TenderSlot[] {
        return slots.map(slot => ({
            ...slot,
            id: Uuid.fromString(slot.id),
            isTaken: slot.taken,
            timeFrom: Time.parseIsoUTC(slot.timeFrom),
            timeTo: Time.parseIsoUTC(slot.timeTo),
            partialOrders: TenderService.mapPartialOrders(slot.partialOrders),
        }));
    }
}

export default new TenderService;
