<template>
  <div>
    <label
      for="comment"
      class="block text-sm font-medium text-gray-700 text-left"
      v-text="label"
    />
    <div class="mt-1">
      <textarea
        id="comment"
        :value="modelValue"
        rows="4"
        name="comment"
        v-bind="$attrs"
        :class="{
          'appearance-none rounded-md block w-full focus:outline-none sm:text-sm rounded-m': true,
          'border-gray-300 placeholder-gray-400 focus:ring-primary-500 focus:border-primary-500': !hasErrors,
          'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500': hasErrors,
        }"
        @input="onInput($event.target)"
      />
    </div>

    <FieldErrors :errors="combinedErrors" />
  </div>
</template>

<script lang="ts" setup>
import {toRefs} from 'vue';
import Rule from '@app/forms/rules/Rule';
import {useErrorHandling} from '@app/forms/useErrorHandling';
import FieldErrors from '@app/forms/FieldErrors.vue';

defineOptions({
    inheritAttrs: false,
});

interface Props {
  label: string;
  modelValue?: string;
  errors?: string[];
  rules?: Rule<string|undefined>[];
}

const props = withDefaults(defineProps<Props>(), {
  rules: () => [],
  errors: () => [],
  modelValue: undefined,
});

interface Events {
  (e: 'update:model-value', value: string): void;
}

const emit = defineEmits<Events>();

const {
  modelValue,
  errors,
  rules,
} = toRefs(props);

const {combinedErrors, hasErrors, resetInternalErrors} = useErrorHandling(rules, modelValue, 'textarea', errors);

const onInput = (target: EventTarget | null) => {
  emit('update:model-value', (target as HTMLTextAreaElement).value);

  resetInternalErrors();
};


</script>
