import {LocationOption} from '@app/forms/Location';
import {onMounted, ref} from 'vue';
import {locationProvider} from '@app/location/LocationProvider';

export const useLocationCombobox = () => {
    const query = ref('');
    const scriptLoading = ref(true);
    const locationOptions = ref<Record<string, LocationOption>>({});

    function onLoaded() {
        scriptLoading.value = false;
        locationProvider().init();
    }

    function attachLocationProviderScript() {
        const scriptUrl = locationProvider().getScriptUrl();

        if (scriptUrl === null) {
            scriptLoading.value = false;
            return;
        }

        const scriptId = 'location-provider-script';
        const scriptTagAlreadyAttached = !!document.getElementById(scriptId);

        if (scriptTagAlreadyAttached) {
            scriptLoading.value = false;
            return;
        }

        // todo: this should not be googleMaps specific, maybe extract it to LocationProviderContract
        (window as any).googleMapsInitialized = onLoaded;

        const scriptTag = document.createElement('script');
        scriptTag.id = scriptId;
        scriptTag.src = scriptUrl;
        document.body.appendChild(scriptTag);
    }

    onMounted(attachLocationProviderScript);

    return {
        query,
        locationOptions,
        scriptLoading,
        locationLabelFromValue: (value: unknown): string => {
            if (!value || !Object.hasOwn(value, 'description')) {
                return '';
            }
            return (value as LocationOption).description;
        },
        onInputChange: async (eventTarget: EventTarget | null) => {
            query.value = (eventTarget as HTMLInputElement).value;
            locationOptions.value = await locationProvider().fetchLocationOptionsByQuery(query.value);
        },
    };
};
