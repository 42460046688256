<template>
  <TrComboBox
    ref="combobox"
    :model-value="modelValue"
    :label="label"
    :items="users"
    :resolve-label="getLabelFromCompanyUser"
    :errors="inputErrors"
    :query="query"
    :rules="rules"
    @update:model-value="$emit('update:model-value', $event as User|undefined)"
    @change="handleComboboxChange($event)"
  />
</template>

<script lang="ts" setup>
import TrComboBox from '@app/forms/TrCombobox.vue';
import {computed, ComputedRef, onMounted, ref, toRefs} from 'vue';
import type {MinimalCompanyUser} from '@app/company/CompanyUser';
import companyService from '@app/company/CompanyService';
import auth, {userId} from '@app/auth/Auth';
import {UuidString} from '@app/uuid/UuidString';
import keyBy from 'lodash/keyBy';
import Rule from '@app/forms/rules/Rule';
import {User} from '@app/auth/User';
import {comboboxItem} from '@app/forms/comboboxItem';
import {useAsyncAction} from '@app/http/useAsyncAction';

interface Props {
    modelValue?: User;
    label: string;
    errors?: string[];
    rules?: Rule<comboboxItem>[];
    blacklist?: Set<UuidString>;
    shouldDefaultCurrentUser?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  errors: () => [],
  rules: () => [],
  blacklist: () => new Set,
});

const {errors, blacklist, modelValue, shouldDefaultCurrentUser} = toRefs(props);

interface Events {
    (e: 'update:model-value', selectedUser: Props['modelValue']): void;
}

const emit = defineEmits<Events>();

const combobox = ref<typeof TrComboBox | null>(null);

const focus = () => {
    combobox.value?.focus();

};

defineExpose({
    focus,
});

const inputErrors = computed(() => errors?.value ?? []);

const query = ref('');

const users: ComputedRef<Record<UuidString, MinimalCompanyUser>> = computed(() => {
    const nonBlackListedUsers = rawData.value?.filter(user => user.id.equals(modelValue?.value?.id) || !blacklist?.value?.has(user.id.toString()));
    return keyBy(nonBlackListedUsers, (user) => user.id.toString());
});

const getLabelFromCompanyUser = (user: unknown) => {
    if (!user || !Object.hasOwn(user, 'id')) {
        return '';
    }

    return `${(user as MinimalCompanyUser).firstName} ${(user as MinimalCompanyUser).lastName}`;
};

const handleComboboxChange = (event: Event) => {
    const eventTarget = event.target! as HTMLInputElement;
    getMatchingCompanyUsers(eventTarget.value);
};

const {execute: getMatchingCompanyUsers, data: rawData} = useAsyncAction(async (newQuery: string) => {
    query.value = newQuery;

    const {data} = await companyService.getAllCompanyUsers(auth.getCompanyId(), newQuery);
    return data;
});

function selectCurrentUser() {
  if (!userId.value) {
    return;
  }

  const currentUser = users.value[userId.value.toString()];
  if (!currentUser) {
    return;
  }

  emit('update:model-value', currentUser);
}

onMounted(async () => {
  await getMatchingCompanyUsers('');

  if (shouldDefaultCurrentUser.value) {
    selectCurrentUser();
  }
});
</script>
